import { render, staticRenderFns } from "./loadMoreData.vue?vue&type=template&id=a21e3480&scoped=true"
import script from "./loadMoreData.vue?vue&type=script&lang=js"
export * from "./loadMoreData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a21e3480",
  null
  
)

export default component.exports